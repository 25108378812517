<template>

  <!-- #region::Transfer pieces modal -->
  <b-modal
    id="update-product-modal"
    ref="update-product-modal"
    title="Actualizar piezas del producto"
    centered
    size="xl"
    @hidden="onCloseModal"
  >
    <b-card-text>
      <b-row>
        <b-col
          cols="12"
        >
          <b-alert
            show
            variant="info"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertCircleIcon"
              />
              <span class="ml-25">Las cotizaciones deben tener productos y piezas de la misma ubicación.</span>
            </div>
          </b-alert>
        </b-col>

        <!-- #region begin::Products list -->
        <b-col>

          <b-skeleton
            v-if="isLoadingProducts"
            animation="wave"
            width="100%"
            height="500px"
          />

          <!-- #region begin::Collapsable products list -->
          <app-collapse
            v-else
            accordion
            type="margin"
          >
            <ProductCollapsableItem
              :key="product.IdProduct"
              :product="product"
              :is-visible="true"
              :show-pieces-list="true"
              :origin-product-id="product.IdProduct"
              :selectable-pieces-list="true"
            />
          </app-collapse>
          <!-- #endregion end::Collapsable products list -->
        </b-col>
        <!-- #endregion end::Products list -->
      </b-row>
    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        @click="onAddPieces"
      >
        {{ addButtonText }}
      </b-button>
    </template>
    <!-- #endregion::Footer -->

  </b-modal>
  <!-- #endregion::Transfer pieces modal -->

</template>

<script>
// #region Imports
import { mapGetters, mapActions, mapState } from 'vuex'
import {
  BRow, BCol, BModal, VBModal, BCardText, BButton, BAlert, BSkeleton,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BModal,
    BButton,
    BCardText,
    BSkeleton,
    AppCollapse,
    ProductCollapsableItem: () => import('@/modules/production/products/components/ProductCollapsableItem.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState('products', ['product', 'isLoadingProducts']),
    ...mapGetters({
      getTransferPieces: 'transfers/getTransferPieces',
      getTransferProducts: 'transfers/getTransferProducts',
      getProvitionalPieces: 'circuits/getProvitionalPieces',
      getProvitionalProducts: 'circuits/getProvitionalProducts',
    }),
    provitionalPieces: {
      get() { return this.getProvitionalPieces },
    },
    provitionalProducts: {
      get() { return this.getProvitionalProducts },
    },
    transferPieces: {
      get() { return this.getTransferPieces },
    },
    transferProducts: {
      get() { return this.getTransferProducts },
    },
    provitionalProduct() {
      return this.provitionalProducts.find(provitionalProduct => provitionalProduct.IdProduct === this.product.IdProduct)
    },
    transferProduct() {
      return this.transferProducts.find(transferProduct => transferProduct.IdProduct === this.product.IdProduct)
    },
    addButtonText() {
      let text = 'Actualizar piezas'

      if (this.provitionalPieces.length > 0) {
        text += ` (${this.provitionalPieces.length})`
      }

      return text
    },
  },
  created() {
    this.loadProduct({ id: this.id })
  },
  methods: {
    ...mapActions({
      loadProduct: 'products/loadProduct',
      clearFormData: 'circuits/clearFormData',
      updateTransferProduct: 'transfers/updateTransferProduct',
    }),
    async showModal() {
      try {
        await this.loadProduct({ id: this.id, withPieces: true })
        this.$refs['update-product-modal'].show()
      } catch (error) {
        this.showToast('Error de validación', 'El producto que intenta editar no existe.', 'warning')
      }
    },
    hideModal() {
      this.$refs['update-product-modal'].hide()
    },
    onAddPieces() {
      if (this.provitionalPieces.length > 0) {
        const isSameLocation = piece => piece.Location === this.provitionalPieces[0].Location

        const sameLocationProduct = this.provitionalPieces.every(isSameLocation)
        const sameLocationTransfer = this.transferPieces.every(isSameLocation)

        if (!sameLocationProduct || !sameLocationTransfer) {
          this.showToast(
            'Error de validación',
            'Las piezas seleccionadas no pertenecen a la misma ubicación',
            'warning',
          )
          return
        }

        this.transferProduct.pieces = this.provitionalProduct.pieces.map(piece => ({ ...piece }))
        this.updateTransferProduct(this.transferProduct)
        this.hideModal()
      } else {
        const transferProduct = { ...this.product }
        transferProduct.pieces = []
        this.updateTransferProduct(transferProduct)

        this.showToast(
          'Producto eliminado',
          'El producto se ha eliminado correctamente de la orden de traspaso',
          'success',
        )
      }
    },
    onCloseModal() {
      this.clearFormData()
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
