<template>
  <b-container
    fluid
    class="px-0 ml-1"
  >
    <b-row
      align-h="between"
    >

      <!-- #region begin::Product details title -->
      <b-col cols="6">
        <TransferProductTitleDetail
          :id="product.IdProduct"
          :name="product.Name"
          :type="product.Type"
          :count="countState"
          :available="availableProduct"
          :save-route="false"
          :set-pieces-query="true"
          :product="product"
        />
      </b-col>
      <!-- #endregion end::Product details title -->

      <b-col cols="6">
        <b-container fluid>
          <b-row
            align-h="end"
            align-v="center"
          >

            <!-- #region begin::Delete product button -->
            <b-button
              v-if="showDeleteButton"
              v-b-tooltip.hover.top="'Eliminar'"
              variant="flat-secondary"
              class="btn-icon px-2"
              @click="onDeleteProduct"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <!-- #endregion end::Delete product button -->

            <!-- #region begin::Edit product button -->
            <b-button
              v-if="showEditButton"
              v-b-tooltip.hover.top="'Editar'"
              variant="flat-secondary"
              class="btn-icon px-2"
              @click="$refs.transferProductsUpdateModal.showModal()"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
            <!-- #endregion end::Edit product button -->

          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <TransferProductDetails
      :product="product"
      @toggle-collapse="collapse"
    />

    <TransferProductsUpdateModal
      v-if="loadEditProductModal || editionModeState"
      :id="product.IdProduct"
      ref="transferProductsUpdateModal"
    />

  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BContainer, BButton, VBTooltip,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TransferProductDetails from '@/modules/production/transfers/components/cards/TransferProductDetails.vue'
import TransferProductTitleDetail from '@/modules/production/transfers/components/cards/TransferProductTitleDetail.vue'
import TransferProductsUpdateModal from '@/modules/production/transfers/components/modals/TransferProductsUpdateModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BContainer,
    TransferProductsUpdateModal,
    TransferProductDetails,
    TransferProductTitleDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    collapseItemId: {
      type: String,
      required: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    loadEditProductModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      iconToggle: 'ChevronDownIcon',
    }
  },
  computed: {
    ...mapGetters({
      getEditionModeState: 'transfers/getEditionModeState',
    }),
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    countState() {
      if (this.$route.name === 'transfer-details') {
        return this.product.QtyIventory
      }
      return this.product.Qty
    },
    availableProduct() {
      if (this.$route.name !== 'transfer-details') {
        return true
      }

      return this.product.Status === 1
    },
  },
  created() {
    this.visible = this.isVisible
    this.setToggleIcon()
  },
  methods: {
    ...mapActions({
      addTransferProduct: 'transfers/addTransferProduct',
    }),
    collapse() {
      if (!this.visible) {
        this.$emit('rendering', true)
      }
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    onDeleteProduct() {
      const transferProduct = { ...this.product }
      transferProduct.pieces = []
      this.addTransferProduct(transferProduct)

      this.showToast(
        'Producto eliminado',
        'El producto se ha eliminado correctamente de la cotización',
        'success',
      )
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
